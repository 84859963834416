import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Address */}
        <div className="footer-section">
          <h4 className="footer-section-title">Our Address</h4>
          <p className="a">Post Box No 232, Postal Code: 611</p>
          <p className="a">Nizwa, Oman</p>
        </div>

        {/* Group Company Information */}
        <div className="footer-section">
          <h4 className="footer-section-title">Group Companies</h4>
          <p className='a'>Nizwa Mining, Wafra Engineering LLC</p>
        </div>

        {/* Google Map */}
        <div className="footer-section">
          <h4 className="footer-section-title">Location</h4>
          <iframe
            className="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.1882137481816!2d57.67959757220686!3d22.873429876103597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e56636548f52f%3A0x1b533c342b2015ab!2sAl%20Rumeli%20Cement%20Product!5e1!3m2!1sen!2som!4v1730827356919!5m2!1sen!2som"
            width="300"
            height="85"
            allowFullScreen=""
            loading="lazy"
            title="Google Map Location"
          ></iframe>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © nizwaeng.com. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
